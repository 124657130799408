.map-container {
  width: 100vw;
  height: 100vh - 60px;
}
.origins__search-bar {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 33.3%;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 0.1);
}

.gm-style-iw > button {
  margin-right: 10px !important;
  margin-top: 15px !important;
}
